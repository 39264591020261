import React from "react";
import { useParams } from "react-router-dom";
import {
  dynamic_route_var_names,
  meeting_attendee_roles,
} from "../../utils/constants/data";
import { getSessionStorageItem } from "../../utils/funcs/storage";
import { session_storage_keys } from "../../utils/constants/storage";
import {
  getHostLeaveUrl,
  getHostLeaveUrlBecauseParallelMeetings,
} from "../../utils/funcs/url";
import { getJwtToken } from "../../utils/apis/auth";
import { fetchMeetingDetails } from "../../utils/apis/meeting";
import { auth_codes } from "../../utils/constants/auth";
import { removeJwtToken } from "../../utils/funcs/auth";
import { checkIfMeetingUrlIsValid } from "../../utils/funcs/meetingAuthUrl";
import { logError } from "../../utils/funcs/logError";
import { debugLogs } from "../../features/Common/modules/DebugLogs/DebugLogs.utils";
import { debugging__all_flows } from "../../features/Common/modules/DebugLogs/DebugLogs.constants";
import { api_status } from "../../utils/constants/api";

const localDebugLogs = ({ data, metadata }) => {
  debugLogs({
    data,
    metadata: {
      ...metadata,
      filePath: "src/pages/ZoomRedirect/ZoomRedirect.js",
      flow: debugging__all_flows.host_referer,
    },
  });
};

// TODO: rename this component
const Client = () => {
  React.useEffect(() => {
    const isMeetingUrlValid = checkIfMeetingUrlIsValid({
      meetingAttendeeRole: meeting_attendee_roles.host,
    });

    localDebugLogs({
      data: { isMeetingUrlValid },
      metadata: { step: 1 },
    });

    if (isMeetingUrlValid) {
      getMeetingDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dynamicRouteParams = useParams();
  const meetingUuid = dynamicRouteParams[dynamic_route_var_names.meetingUuid];

  const getMeetingDetails = async () => {
    const { userEmail, userId, leaveUrl } =
      getSessionStorageItem(session_storage_keys.user_details) || {};

    localDebugLogs({
      data: { userEmail, userId, leaveUrl },
      metadata: { step: 2 },
    });

    try {
      const jwtToken = await getJwtToken({
        userId,
        meetingAttendeeRole: meeting_attendee_roles.host,
      });
      const fetchMeetingDetailsApiResponse = await fetchMeetingDetails({
        meetingAttendeeRole: meeting_attendee_roles.host,
        jwtToken,
        meetingUuid,
        userEmail,
        shareStartUrl: true,
      });
      const fetchMeetingDetailsApiData = fetchMeetingDetailsApiResponse?.data;

      localDebugLogs({
        data: {
          "fetchMeetingDetailsApiData?.status":
            fetchMeetingDetailsApiData?.status,
        },
        metadata: { step: 3 },
      });

      if (
        [
          auth_codes.unknown_user,
          auth_codes.refresh_token_expired,
          auth_codes.access_token_missing,
          auth_codes.invalid_jwt_token,
          auth_codes.access_token_expired,
        ]
          .map((i) => i.status_code)
          .includes(fetchMeetingDetailsApiData?.status)
      ) {
        // we need to refetch token and start again
        removeJwtToken();
        getMeetingDetails();
        return;
      }

      localDebugLogs({
        data: {
          "fetchMeetingDetailsApiData?.meetingStartUrl":
            fetchMeetingDetailsApiData?.meetingStartUrl,
        },
        metadata: { step: 4 },
      });

      if (fetchMeetingDetailsApiData?.meetingStartUrl) {
        window.location.href = fetchMeetingDetailsApiData?.meetingStartUrl;
      } else if (
        fetchMeetingDetailsApiResponse?.status === api_status.NOT_ACCEPTABLE
      ) {
        window.location.href = getHostLeaveUrlBecauseParallelMeetings({
          message: fetchMeetingDetailsApiData?.message || "",
        });
      } else {
        window.location.href = getHostLeaveUrl();
      }
    } catch (err) {
      localDebugLogs({
        data: { err },
        metadata: { step: 5 },
      });

      logError({
        error: err,
        when: "getMeetingDetails function call",
        occuredAt: "src/pages/ZoomRedirect/ZoomRedirect.js",
      });
    }
  };

  return <div />;
};
export default Client;
