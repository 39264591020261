import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

/* Routes start */
import { Home } from "./pages/Home";
/* Routes end */

import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  dynamic_route_var_names,
  meeting_attendee_roles,
} from "./utils/constants/data";
import ZoomRedirect from "./pages/ZoomRedirect/ZoomRedirect";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    debug: true,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route
          path={"/host/:" + dynamic_route_var_names.meetingUuid}
          element={<ZoomRedirect />}
        />
        {/* NOTE: This is not removed from here as in future we might have to come back to this 
                  functionality where we use this 'Home' page for hose meeting as well
                  Please remove this incase this is still here by 19 July 2025
        */}
        {/* <Route
          path={"/host/:" + dynamic_route_var_names.meetingUuid}
          element={<Home meetingAttendeeRole={meeting_attendee_roles.host} />}
        /> */}
        <Route
          path={"/customer/:" + dynamic_route_var_names.meetingUuid}
          element={
            <Home meetingAttendeeRole={meeting_attendee_roles.customer} />
          }
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
